import React from 'react'
import { Grid, Card, Tab } from 'semantic-ui-react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

import ContactUsForm from '../components/ContactUsForm'
import LeadForm from '../components/LeadForm'

const panes = [
  {
    menuItem: 'General',
    render: () => <ContactUsForm />
  },
  {
    menuItem: 'New Purchase',
    render: () => <LeadForm />
  }
]

const ContactUsPage = () => (
  <>
    <Layout>
      <SEO
        title="Contact Us"
        description="Looking for the safe and beautiful pedicure spa chairs for your salon? Our sales team is here to assist you to choose the best Contego spa chair series with your salon. CALL US at (714) 895-8865 NOW to get instant help!!!"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />

      <div className="row  px-0 py-4">
        <div className="col">
          <br />
          <div className="p-4 rounded">
            <h1 className="text-center">OUR SHOWROOMS</h1>
            <p className="text-center">
              Stop by to experience{' '}
              <strong className="text-secondary text-bold">
                THE WORLD&apos;S FIRST DISPOSABLE PEDICURE SPA JET
              </strong>
            </p>
          </div>
          <br />
          <Grid columns="2" stackable padded>
            <Grid.Column>
              <Card fluid>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5519.927823646978!2d-117.95541615301237!3d33.774299626182454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2614190e99f7%3A0x7866e29281e323a1!2sContego%20Spa%20Designs!5e0!3m2!1sen!2sus!4v1570034391494!5m2!1sen!2sus"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  className="m-0"
                  title="garden grove showroom"
                />
                <Card.Content className="text-center py-4">
                  <h2 className="text-center">GARDEN GROVE, CA</h2>
                  <Card.Description className="text-center text-large">
                    <a
                      href="https://goo.gl/maps/z7iY7nsy7sLVv1f28"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-center"
                    >
                      12856 Brookhurst St, Garden Grove, CA 92840
                    </a>
                    <br />
                    <br />

                    <p className="d-block mb-0">
                      <strong className="text-secondary">MON-FRI</strong> 10:00AM - 6:00PM (PT)
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary">SAT</strong> 10:00AM - 3:00PM (PT)
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary">SUN</strong> 10:00AM - 5:00PM (PT)
                    </p>
                    <br />

                    <a href="tel:7148958865" className="text-center my-4">
                      (714) 895-8865
                    </a>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>

            <Grid.Column>
              <Card fluid>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9751.037284441582!2d-95.56976097480528!3d29.703475401994872!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd96ba00d563e7808!2sCONTEGO%20SPA%20DESIGNS%20Houston%20Showroom!5e0!3m2!1sen!2sus!4v1570118953949!5m2!1sen!2sus"
                  width="100%"
                  height="450"
                  frameBorder="0"
                  className="m-0"
                  title="texas showroom"
                />
                <Card.Content className="text-center py-4">
                  <h2 className="text-center">HOUSTON, TX</h2>
                  <Card.Description className="text-center text-large">
                    <a
                      href="https://goo.gl/maps/tS79wr6Dnm8vazNf8"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="text-center"
                    >
                      6787A Wilcrest Dr Suite A, Houston, TX 77072
                    </a>
                    <br />

                    <br />
                    <p className="d-block mb-0">
                      <strong className="text-secondary">MON - FRI</strong> 10:00AM - 6:00PM (CT)
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary">SAT</strong> 11:00AM - 5:00PM (CT)
                    </p>
                    <p className="d-block mb-0">
                      <strong className="text-secondary">SUN</strong> 11:00AM - 6:00PM (CT)
                    </p>
                    <br />

                    <a href="tel:7148958865" className="text-center py-2">
                      (714) 895-8865
                    </a>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid>
          <br />
        </div>
      </div>

      <div className="container-fluid d-flex flex-column justify-content-center align-items-center px-0 py-4 bg-light">
        <h1 className="w-100 text-center">ADMINISTRATIVE</h1>
        <div className="d-flex flex-column align-items-center text-center text-large">
          <a href="https://goo.gl/maps/z7iY7nsy7sLVv1f28" rel="noopener noreferrer" target="_blank">
            12856 Brookhurst St, Garden Grove, CA 92840
          </a>
          <a href="tel:714-895-8865" className="py-4">
            (714) 895-8865
          </a>
          <a href="mailto:contact@contegospa.com" className="pb-4">
            contact@contegospa.com
          </a>
          <p className="d-block mb-0">
            <strong className="text-secondary mr-2">MON - FRI</strong> 09:00AM - 6:00PM (PT)
          </p>
          <p className="d-block mb-0">
            <strong className="text-secondary mr-2">SAT</strong> CLOSED
          </p>
          <p className="d-block mb-0">
            <strong className="text-secondary mr-2">SUN</strong> CLOSED
          </p>
        </div>
        <br />
      </div>

      <div className="row">
        <div className="col px-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5519.927823646978!2d-117.95541615301237!3d33.774299626182454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2614190e99f7%3A0x7866e29281e323a1!2sContego%20Spa%20Designs!5e0!3m2!1sen!2sus!4v1570034391494!5m2!1sen!2sus"
            width="100%"
            height="450"
            frameBorder="0"
            className="m-0"
            title="administrative location"
          />
        </div>
      </div>

      <div className="row px-0 py-4">
        <div className="col">
          <br />
          <h1 className="text-center px-5">CONTACT OUR SALES TEAM</h1>
          <p className="text-center px-5">
            Our team is here to assist you to choose the best and safest pedicure chairs for your
            salon.
          </p>
          <div className="container">
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
          </div>
          <br />
        </div>
      </div>
    </Layout>
  </>
)

export default ContactUsPage
