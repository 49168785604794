import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { Form } from 'semantic-ui-react'

const TextArea = ({ ...props }) => <Field component={TextAreaWrapper} {...props} />

const TextAreaWrapper = ({ form: { touched, errors, isSubmitting }, field, ...props }) => {
  const { name } = field
  const { label, placeholder, required, disabled, minRow } = props
  return (
    <>
      <Form.Field
        required={required}
        error={errors[name] && touched[name]}
        disabled={disabled || isSubmitting}
      >
        <label>{label}</label>
        <div className="ui fluid input">
          <textarea name={name} placeholder={placeholder} {...field} rows={minRow} />
        </div>
        <ErrorMessage name={name} render={renderError} />
      </Form.Field>
    </>
  )
}

const renderError = msg => <div className="ui pointing above prompt label">{msg}</div>

export default TextArea
