import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import {
  Form,
  Grid,
  Message,
  Progress,
  Dropdown,
  Button,
  checkbox,
  Checkbox
} from 'semantic-ui-react'
import ReCAPTCHA from 'react-google-recaptcha'

// custom build components
import TextInput from './TextInput'
import TextArea from './TextArea'

// contact services
import { submitLeadForm } from '../services/bullarum.service'

import useTimeoutMessage from '../hooks/useTimeoutMessage'

const PRODUCTS = [
  { value: 'Spa Chair NOVO model', text: 'Spa Chair NOVO model', key: 'scnovo' },
  { value: 'Spa Chair TERGO model', text: 'Spa Chair TERGO model', key: 'sctg' },
  { value: 'Spa Chair VSPORT model', text: 'Spa Chair VSPORT model', key: 'scvs' },
  { value: 'Spa Chair MAGNA model', text: 'Spa Chair MAGNA model', key: 'scmn' },
  { value: 'Spa Chair VIP model', text: 'Spa Chair VIP model', key: 'scvip' },
  { value: 'Pedi Stool', text: 'Pedi Stool', key: 'pds' },
  { value: 'Mani Stool', text: 'Mani Stool', key: 'ms' },
  { value: 'Tech Chair', text: 'Tech Chair', key: 'tc' },
  { value: 'Customer Chair', text: 'Customer Chair', key: 'cc' },
  { value: 'Nail Table', text: 'Nail Table', key: 'nt' },
  { value: 'Others', text: 'Others', key: 'others' }
]

// define contact us schema for validation
const ContactSchema = Yup.object().shape({
  customerName: Yup.string()
    .required('Your name is required')
    .min(2, 'Invalid input')
    .max(25, 'Invalid input')
    .trim(),
  businessName: Yup.string()
    .required('Business name is required')
    .trim()
    .min(4, 'Invalid input')
    .max(40, 'Invalid input'),
  email: Yup.string().matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format'),
  phoneNumber: Yup.string()
    .matches(/[0-9()\-\s]{10,20}$/, 'Invalid input')
    .max(15, 'Invalid input'),
  businessAddress: Yup.string()
    .min(10)
    .max(50),
  numberOfChair: Yup.number()
    .min(0)
    .max(200)
    .typeError('Invalid input'),
  products: Yup.array()
    .of(Yup.string())
    .required('Please add at least one product you are interested at!!!'),
  message: Yup.string()
    .required('Message is required')
    .min(20, 'Minimum 20 characters')
    .max(100, 'Maximum 200 characters'),
  createdAt: Yup.date().default(new Date())
})

const defaultValues = {
  customerName: '',
  businessName: '',
  email: '',
  numberOfChair: '',
  phoneNumber: '',
  businessAddress: '',
  products: [],
  message: ''
}

const ContactUsForm = () => {
  const recaptchaRef = React.useRef()

  const [errorMessage, setErrorMessage] = useTimeoutMessage('')
  const [successMessage, setSuccessMessage] = useTimeoutMessage('')
  const [language, setLanguage] = React.useState('en')

  const executeRecaptcha = () => {
    recaptchaRef.current.execute()
  }

  const handleExpired = () => {}

  const handleSubmission = React.useCallback(
    (values, actions) => {
      actions.setSubmitting(true)
      const {
        customerName,
        businessName,
        businessAddress,
        phoneNumber,
        numberOfChair,
        products,
        email,
        message
      } = values

      // sanitize data before sending
      if (recaptchaRef.current.getValue()) {
        const sanitizedData = {
          customerName: _.escape(_.trim(customerName)),
          businessName: _.escape(_.trim(businessName)),
          email: _.trim(email),
          message: _.escape(_.trim(message)),
          businessAddress: _.escape(_.trim(businessAddress)),
          phoneNumber: _.escape(_.trim(phoneNumber)),
          products,
          numberOfChair: _.escape(_.trim(numberOfChair))
        }

        // submitting form value
        submitLeadForm(sanitizedData)
          .then(res => {
            setSuccessMessage(res.data.message, 5000)
          })
          .catch(error => {
            if (error.response) {
              setErrorMessage(error.response.data, 5000)
            } else {
              setErrorMessage('Something wrong happened. Please try again later!!!', 5000)
            }
          })
          .finally(() => {
            actions.setSubmitting(false)
            actions.resetForm({
              customerName: '',
              businessName: '',
              email: '',
              phoneNumber: '',
              businessAddress: '',
              products: [],
              message: ''
            })
          })
      }
    },
    [recaptchaRef, setErrorMessage, setSuccessMessage]
  )

  const translateToVietnamese = React.useCallback(() => {
    if (language === 'en') {
      setLanguage('vn')
    } else {
      setLanguage('en')
    }
  }, [language])

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmission}
      validationSchema={ContactSchema}
    >
      {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
        <>
          <Form noValidate className="attached fluid segment rounded shadow">
            {isSubmitting && <Progress percent={100} attached="top" active size="medium" />}
            <Button primary type="button" onClick={translateToVietnamese}>
              {language === 'en' ? 'Vietnamese (Tiếng Việt)' : 'In English'}
            </Button>

            {language === 'vn' && (
              <p>
                Những câu hỏi với dấu <strong className="text-secondary">*</strong> là những câu hỏi
                bắt buộc.
              </p>
            )}

            <Grid stackable padded>
              <Grid.Row columns="3">
                <Grid.Column>
                  <TextInput
                    name="customerName"
                    label={language === 'en' ? 'Your Name' : 'Họ Tên'}
                    placeholder={
                      language === 'en'
                        ? 'Please enter your full name'
                        : 'Vui lòng điền đầy đủ họ tên của quí anh/chị'
                    }
                    autoComplete="name"
                    required
                  />
                </Grid.Column>

                <Grid.Column>
                  <TextInput
                    name="email"
                    label="Email"
                    placeholder="example@domain.com"
                    autoComplete="email"
                  />
                </Grid.Column>

                <Grid.Column>
                  <TextInput
                    name="phoneNumber"
                    label={language === 'en' ? 'Phone Number' : 'Số Điện Thoại'}
                    placeholder="(xxx) xxx-xxxx"
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns="2">
                <Grid.Column>
                  <TextInput
                    name="businessName"
                    label={language === 'en' ? 'Business Name' : 'Tên Doanh Nghiệp'}
                    placeholder={
                      language === 'en' ? 'Your business name' : 'Tên doanh nghiệp của quí anh/chị'
                    }
                    required
                  />
                </Grid.Column>

                <Grid.Column>
                  <TextInput
                    name="businessAddress"
                    label={language === 'en' ? 'Business Address' : 'Địa Chỉ Doanh Nghiệp'}
                    placeholder={
                      language === 'en'
                        ? 'Your business address'
                        : 'Địa chỉ doanh nghiệp của quí anh/chị'
                    }
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns="1">
                <Grid.Column className="ui required field">
                  <label>{language === 'en' ? 'Products' : 'Sản Phẩm Quan Tâm'}</label>
                  <Dropdown
                    placeholder={
                      language === 'en'
                        ? 'Which products do you want to purchase?'
                        : 'Quí anh/chị đang quan tâm đến sản phẩm nào của Contégo?'
                    }
                    fluid
                    multiple
                    selection
                    options={PRODUCTS}
                    value={values.products}
                    onChange={(e, selectedProducts) => {
                      setFieldValue('products', [...selectedProducts.value])
                    }}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns="1">
                <Grid.Column className="ui required field">
                  <TextInput
                    name="numberOfChair"
                    label={language === 'en' ? 'Number of Chair' : 'Số Lượng Ghế'}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <TextArea
                    name="message"
                    label={language === 'en' ? 'Message' : 'Lời Nhắn'}
                    placeholder={
                      language === 'en'
                        ? 'Tell us about your question'
                        : 'Vui lòng để lại lời nhắn của quí anh/chị'
                    }
                    minRow="10"
                    required
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  Phone Number Collection Consent: Contego Spa Designs values your privacy and aims
                  to maintain the highest standards in protecting your personal information. As part
                  of our commitment to providing excellent service, we would like to collect your
                  phone number for specific purposes outlined below. Kindly read the following
                  carefully and provide your consent: Purpose of Phone Number Collection: Sending
                  order updates and confirmations related to your purchases. Notifying you about
                  delivery or service-related information. Informing you about any changes to our
                  products, services, or policies. Responding to any inquiries or customer support
                  requests you may have. Marketing and Promotions updates. Your Consent: By
                  providing your phone number and submitting this form, you hereby give your
                  explicit consent for Contego Spa Designs to collect and store your phone number
                  for the purposes mentioned above. Rest assured, we will not share, sell, or
                  disclose your phone number to any third parties for marketing purposes. Your Right
                  to Withdraw Consent: Your consent is entirely voluntary, and you have the right to
                  withdraw it at any time. If you decide to withdraw your consent, Contego Spa
                  Designs will Opt you out from recieving communications via text message from us,
                  and you will no longer receive communications for the purposes mentioned above.
                  How to Withdraw Consent: To withdraw your consent please contact us at
                  contact@contegospa.com. Data Security: We take the security of your personal
                  information seriously and have implemented appropriate technical and
                  organizational measures to safeguard it. For more information, please refer to our
                  Privacy Policy.
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Checkbox
                    name="optin"
                    label={
                      language === 'en'
                        ? 'I hereby give my consent for Contego Spa Designs to collect and store my phone number for the purposes mentioned above.'
                        : 'I hereby give my consent for Contego Spa Designs to collect and store my phone number for the purposes mentioned above.'
                    }
                  />
                </Grid.Column>
              </Grid.Row>

              {isValid && (
                <Grid.Row>
                  <Grid.Column>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6Le0asMUAAAAALU8Bsu2PvNdUevkl9mM4s8YmT7F"
                      onChange={handleSubmit}
                      onExpired={handleExpired}
                      badge="inline"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row>
                <Grid.Column>
                  <Button
                    type="button"
                    primary
                    onClick={executeRecaptcha}
                    disabled={isSubmitting || !isValid}
                  >
                    Submit
                  </Button>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  By submitting this form, you acknowledge that you have read and understood the
                  information provided above and consent to the collection and use of your phone
                  number as specified.
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          {errorMessage !== '' && (
            <Message
              attached="bottom"
              negative
              content={
                language === 'en'
                  ? 'Something wrong happens. Please contact us directly at (714) 895-8865!!!'
                  : 'Thành thật xin lỗi quí anh chị vì vấn đề trục trặc hệ thống. Xin quí anh chị liên hệ trực tiếp số điện thoại (714) 895-8865 để được tư vấn trực tiếp'
              }
            />
          )}
          {successMessage !== '' && (
            <Message
              attached="bottom"
              positive
              content={
                language === 'en'
                  ? 'Thank you for contacting us. We will get back to you within two business days!!!'
                  : 'Xin chân thành cảm ơn quí anh/chị đã liên hệ với Contégo. Nhân viên tư vấn của chúng tôi sẽ liên hệ và hỗ trợ quý anh chị trong vòng 2 ngày làm việc (trừ ngày lễ hoặc cuối tuần)'
              }
            />
          )}
        </>
      )}
    </Formik>
  )
}

export default ContactUsForm
