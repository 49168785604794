import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Form, Grid, Message, Progress, Button } from 'semantic-ui-react'
import ReCAPTCHA from 'react-google-recaptcha'

// custom build components
import TextInput from './TextInput'
import TextArea from './TextArea'

// contact services
import { submitContactForm } from '../services/bullarum.service'

import useTimeoutMessage from '../hooks/useTimeoutMessage'

// define contact us schema for validation
const ContactSchema = Yup.object().shape({
  customerName: Yup.string()
    .required('Your name is required')
    .min(2, 'Invalid input')
    .max(25, 'Invalid input')
    .trim(),
  businessName: Yup.string()
    .required('Business name is required')
    .trim()
    .min(4, 'Invalid input')
    .max(40, 'Invalid input'),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email format')
    .required('Email is required'),
  message: Yup.string()
    .required('Message is required')
    .min(20, 'Minimum 20 characters')
    .max(100, 'Maximum 200 characters'),
  createdAt: Yup.date().default(new Date())
})

const defaultValues = {
  customerName: '',
  businessName: '',
  email: '',
  message: ''
}

const ContactUsForm = () => {
  const recaptchaRef = React.createRef()

  const [errorMessage, setErrorMessage] = useTimeoutMessage('')
  const [successMessage, setSuccessMessage] = useTimeoutMessage('')

  const executeRecaptcha = () => {
    recaptchaRef.current.execute()
  }

  const handleExpired = () => {}

  const handleSubmission = React.useCallback(
    (values, actions) => {
      actions.setSubmitting(true)
      const { customerName, businessName, email, message } = values

      // sanitize data before sending
      if (recaptchaRef.current.getValue()) {
        const sanitizedData = {
          customerName: _.escape(_.trim(customerName)),
          businessName: _.escape(_.trim(businessName)),
          email: _.trim(email),
          message: _.escape(_.trim(message))
        }

        // submitting form value
        submitContactForm(sanitizedData)
          .then(res => {
            setSuccessMessage(res.data.message, 5000)
          })
          .catch(error => {
            if (error.response) {
              setErrorMessage(error.response.data, 5000)
            } else {
              setErrorMessage('Something wrong happened. Please try again later!!!', 5000)
            }
          })
          .finally(() => {
            actions.setSubmitting(false)
            actions.resetForm()
          })
      }
    },
    [recaptchaRef, setErrorMessage, setSuccessMessage]
  )

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmission}
      validationSchema={ContactSchema}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <>
          <Form noValidate className="attached fluid segment rounded shadow">
            {isSubmitting && <Progress percent={100} attached="top" active size="medium" />}
            <Grid stackable padded>
              <Grid.Row columns="2">
                <Grid.Column>
                  <TextInput
                    name="customerName"
                    label="Your Name"
                    placeholder="Please enter your full name"
                    autoComplete="name"
                    required
                  />
                </Grid.Column>

                <Grid.Column>
                  <TextInput
                    name="email"
                    label="Email"
                    placeholder="example@domain.com"
                    autoComplete="email"
                    required
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <TextInput
                    name="businessName"
                    label="Business Name"
                    placeholder="Your business name"
                    required
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <TextArea
                    name="message"
                    label="Message"
                    placeholder="Tell us about your question"
                    minRow="10"
                    required
                  />
                </Grid.Column>
              </Grid.Row>

              {isValid && (
                <Grid.Row>
                  <Grid.Column>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6Le0asMUAAAAALU8Bsu2PvNdUevkl9mM4s8YmT7F"
                      onChange={handleSubmit}
                      onExpired={handleExpired}
                      badge="inline"
                    />
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row>
                <Grid.Column>
                  <Button
                    type="button"
                    primary
                    onClick={executeRecaptcha}
                    disabled={isSubmitting || !isValid}
                  >
                    Send
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          {errorMessage !== '' && (
            <Message
              attached="bottom"
              negative
              content="Something wrong happens. Please contact us back later!!!"
            />
          )}
          {successMessage !== '' && (
            <Message
              attached="bottom"
              positive
              content="Thank you for contacting us. We will get back to you within two business days!!!"
            />
          )}
        </>
      )}
    </Formik>
  )
}

export default ContactUsForm
